import React,{Fragment} from 'react'
import {Link} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo/seo";
import logo from '../../assets/img/virsat-logo-light.svg'
import AOS from "aos";

const ThankYouForm = ({location}) => {
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  return (
    <Fragment>
      <Seo
        title='Thank You | VIRSAT - Virtual Reality Safety Training'
        description='Thank You'
      />
      <div className="main-container thank-you-lp -lp">
        <div className="thank-you-lp-bg">
          <StaticImage
            src='../../assets/img/pages/newsletter/virsat-newsletter-background.jpg'
            alt='VIRSAT Thank You Backgorund'
            placeholder='blurred'
            objectFit
          />
        </div>
        <div className="row" data-aos='zoom-in' data-aos-duration='500'>
          <div className="column show-overflow">
            <h1 className="main-title light center">Thank You!</h1>
            <p className="light description center">
            The form was successfully submitted. If you require urgent  information or assistance, you may contact us on <a href="tel:+97126436640">+971 2 6436640</a> or emails us at <a href="mailto:info@virsat.com">info@virsat.com</a>
            </p>

            <Link to="/" className="thank-you-logo">
              <img src={logo} alt="VIRSAT Logo" title="VIRSAT Logo"/>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ThankYouForm
